import { Split, SplitMap } from "@hypertune/sdk/src/shared";
import Label from "../../../components/Label";
import SplitControl, { SplitControlIntentMap } from "./SplitControl";
import EmptyPanel from "./EmptyPanel";
import Panel from "../expression/Panel";
import DiffContainer from "./DiffContainer";
import TypeIcon from "../../../components/icons/TypeIcon";
import { SplitDiffMeta } from "./getSplitDiffsMetaAndIntentMap";
import { Intent } from "../../../components/intent";
import { ExpressionControlContext } from "../../../lib/types";

export default function SplitsDiff({
  currentSplits,
  newSplits,
  splitsDiffsMeta,
  newSplitsIntentMap,
  selectedDiffIndex,
  currentContext,
}: {
  currentSplits: SplitMap;
  newSplits: SplitMap;
  splitsDiffsMeta: SplitDiffMeta[];
  newSplitsIntentMap: Record<string, SplitControlIntentMap>;
  selectedDiffIndex: number | null;
  currentContext: ExpressionControlContext;
}): React.ReactElement | null {
  if (splitsDiffsMeta.length === 0) {
    return null;
  }

  return (
    <>
      {splitsDiffsMeta.map(({ splitId, splitType, longLabel }, index) => (
        <DiffContainer
          header={
            <>
              <TypeIcon type={splitType} size="small" />
              <Label type="title3">{longLabel}</Label>
            </>
          }
          left={
            <SplitControlInner
              split={currentSplits[splitId] ?? null}
              missingIntent="danger"
              intentMap={newValueIntentMapToOldValueIntentMap(
                newSplitsIntentMap[splitId]
              )}
              currentContext={currentContext}
            />
          }
          right={
            <SplitControlInner
              split={newSplits[splitId] ?? null}
              missingIntent="success"
              intentMap={newSplitsIntentMap[splitId]}
              currentContext={currentContext}
            />
          }
          isSelected={selectedDiffIndex === index}
        />
      ))}
    </>
  );
}

function SplitControlInner({
  split,
  missingIntent,
  intentMap,
  currentContext,
}: {
  split: Split | null;
  missingIntent: Intent;
  intentMap: SplitControlIntentMap | undefined;
  currentContext: ExpressionControlContext;
}): React.ReactElement | null {
  if (!split) {
    return <EmptyPanel intent={missingIntent} context={currentContext} />;
  }
  return (
    <Panel
      header={null}
      message={null}
      shouldStack={false}
      context={currentContext}
      disablePanelOnSelect={false}
      expressionId=""
    >
      <SplitControl typeName={split.type} split={split} intentMap={intentMap} />
    </Panel>
  );
}

function newValueIntentMapToOldValueIntentMap(
  intentMap: SplitControlIntentMap | undefined
): SplitControlIntentMap | undefined {
  if (!intentMap) {
    return undefined;
  }
  return flipSuccessAndDanger(intentMap) as SplitControlIntentMap;
}

function flipSuccessAndDanger(data: object): object {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => [
      key,
      value === "success"
        ? "danger"
        : value === "danger"
          ? "success"
          : typeof value === "object"
            ? flipSuccessAndDanger(value)
            : value,
    ])
  );
}
