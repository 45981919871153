import React from "react";
import { SplitMap, fieldPathSeparator } from "@hypertune/sdk/src/shared";
import toStartCase from "@hypertune/shared-internal/src/toStartCase";
import Separator from "../../../components/icons/Separator";
import {
  boldFontWeight,
  interFontFamily,
  mediumFontSize,
  normal,
} from "../../../lib/constants";
import { useAppSelector } from "../../../app/hooks";
import ValueTypeConstraintIcon from "../expression/ValueTypeConstraintIcon";
import TypeIcon from "../../../components/icons/TypeIcon";
import { useSchemaEditorSelectedType } from "../schema/schemaHooks";
import { ProjectView } from "../projectHooks";
import {
  useLogicSelectedFieldPath,
  useLogicSetSelectedFieldPath,
} from "../logicHooks";
import TopBarDropdown from "../../../components/TopBarDropdown";
import { viewCompleteTreeSuffix } from "../expression/ExpressionView";
import { ExpressionNodeMap, findItemInTree } from "../expression/toTree";
import { TopLevelEnum } from "../../../lib/types";
import { useSplitEditorSelectedSplitId } from "../split/splitHooks";

export default function SelectedEntityName({
  selectedView,
  topLevelEnumTypeName,
  defaultFieldPath,
  expressionTree,
  splits,
}: {
  selectedView: ProjectView;
  topLevelEnumTypeName: TopLevelEnum;
  defaultFieldPath: string[];
  expressionTree?: ExpressionNodeMap;
  splits?: SplitMap;
}): React.ReactElement | null {
  const [selectedSplitId] = useSplitEditorSelectedSplitId();
  const logicSelectedFieldPath = useLogicSelectedFieldPath();
  const [schemaSelectedType] = useSchemaEditorSelectedType();
  const selectedSplit =
    selectedSplitId && splits ? splits[selectedSplitId] : null;

  if (selectedView === "logic" && logicSelectedFieldPath && expressionTree) {
    const selected = findItemInTree(
      expressionTree,
      null,
      logicSelectedFieldPath.split(fieldPathSeparator)
    );
    return (
      <>
        <TopLevelLogicEnumDropDown
          topLevelEnum={topLevelEnumTypeName}
          selectedFieldPath={logicSelectedFieldPath}
        />
        {!logicSelectedFieldPath.endsWith(viewCompleteTreeSuffix) &&
          logicSelectedFieldPath !==
            defaultFieldPath.join(fieldPathSeparator) &&
          selected.item && (
            <SelectedEntityContainer
              icon={
                <ValueTypeConstraintIcon
                  isVariable={!!selected.item.variableContext}
                  hasChildren={!!selected.item.childExpressions}
                  valueTypeConstraint={selected.item.valueTypeConstraint}
                />
              }
              label={
                selected.item.selectedFieldLabel ?? selected.item.fieldLabel
              }
            />
          )}
      </>
    );
  }
  if (selectedView === "schema" && schemaSelectedType) {
    return (
      <SelectedEntityContainer
        icon={<TypeIcon type={schemaSelectedType.type} size="small" />}
        label={toStartCase(schemaSelectedType.name)}
      />
    );
  }
  if (selectedView === "splits" && selectedSplit) {
    return (
      <SelectedEntityContainer
        icon={<TypeIcon type={selectedSplit.type} size="small" />}
        label={selectedSplit.name}
      />
    );
  }

  return null;
}

function TopLevelLogicEnumDropDown({
  selectedFieldPath,
  topLevelEnum,
}: {
  selectedFieldPath: string;
  topLevelEnum: TopLevelEnum;
}): React.ReactElement | null {
  const setSelectedFieldPath = useLogicSetSelectedFieldPath();

  const schema = useAppSelector((state) => state.project.draftCommit?.schema);

  const selectedFieldPathSteps = selectedFieldPath.split(fieldPathSeparator);

  if (!schema || !topLevelEnum || selectedFieldPathSteps.length < 2) {
    return null;
  }
  const selectedEnumValue = selectedFieldPathSteps[1];

  return (
    <>
      <Separator />
      <TopBarDropdown
        value={{
          value: selectedEnumValue,
          label: toStartCase(selectedEnumValue.toLowerCase()),
        }}
        placeholder=""
        options={{
          type: "options",
          options: Object.keys(schema.enums[topLevelEnum.typeName].values).map(
            (value) => ({
              value,
              label: toStartCase(value.toLowerCase()),
            })
          ),
        }}
        onChange={(newOption) => {
          if (!newOption) {
            return;
          }
          const newSelectedFieldPath = [
            selectedFieldPathSteps[0],
            newOption.value,
            ...selectedFieldPathSteps.slice(2),
          ].join(fieldPathSeparator);

          setSelectedFieldPath(newSelectedFieldPath);
        }}
        dropdownStyle={{
          sectionMaxHeight: 151,
          muted: "button",
        }}
      />
    </>
  );
}

function SelectedEntityContainer({
  icon,
  label,
}: {
  icon: React.ReactNode;
  label: string;
}): React.ReactElement | null {
  const labels = label.split(fieldPathSeparator);
  return (
    <>
      {labels.map((step, index) => (
        <>
          <Separator />
          <div
            key={`selected-entity-${step}`}
            style={{
              fontFamily: interFontFamily,
              fontSize: mediumFontSize,
              fontWeight: boldFontWeight,
              lineHeight: "14px",
              padding: "8px 8px",
              gap: normal,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              minWidth: 0,
              flexShrink: 2,
              cursor: "default",
            }}
          >
            {index === 0 ? icon : <TypeIcon type="enum" size="small" />}
            <div
              style={{
                minWidth: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                lineHeight: 1.5,
              }}
            >
              {step}
            </div>
          </div>
        </>
      ))}
    </>
  );
}
