import {
  fieldPathSeparator,
  ApplicationExpression,
  Expression,
  GetFieldExpression,
  VariableExpression,
} from "@hypertune/sdk/src/shared";
import {
  isFieldArgumentsObjectVariableExpression,
  toStartCase,
} from "@hypertune/shared-internal";
import getConstraintFromValueType from "@hypertune/shared-internal/src/expression/constraint/getConstraintFromValueType";
import getFieldPathToValueType from "@hypertune/shared-internal/src/expression/getFieldPathToValueType";
import isValueTypeCompatible from "@hypertune/shared-internal/src/expression/constraint/isValueTypeCompatible";
import isValueTypeValid from "@hypertune/shared-internal/src/expression/isValueTypeValid";
import { VariableMap } from "@hypertune/shared-internal/src/expression/types";
import { twJoin } from "tailwind-merge";
import createApplication from "../../../lib/expression/createApplication";
import {
  liftPermissionsDeniedErrorMessage,
  normal,
  singlePanelInnerHeight,
} from "../../../lib/constants";
import {
  ExpressionControlContext,
  IncludeExpressionOptionFunction,
} from "../../../lib/types";
import toLabeledOption from "../../../lib/toLabeledOption";
import Dropdown from "../../../components/Dropdown";
import ExpressionControl from "./ExpressionControl";
import isReadOnly from "../../../lib/expression/isReadOnly";
import {
  intentToLightBgClassName,
  intentToTextColorName,
} from "../../../components/intent";
import TypeIcon from "../../../components/icons/TypeIcon";
import InsertExpressionControl from "./InsertExpressionControl";

export default function GetFieldExpressionControl({
  context,
  variables,
  setVariableName,
  parentExpression,
  expression,
  setExpression,
  optionsButton,
  includeExpressionOption,
  shouldStack,
  disablePanelOnSelect,
}: {
  context: ExpressionControlContext;
  variables: VariableMap;
  setVariableName: { [variableId: string]: (newVariableName: string) => void };
  parentExpression: Expression | null;
  expression: GetFieldExpression;
  setExpression: (newExpression: Expression | null) => void;
  optionsButton: React.ReactNode;
  includeExpressionOption: IncludeExpressionOptionFunction;
  shouldStack: boolean;
  disablePanelOnSelect?: boolean;
}): React.ReactElement {
  const fieldValueTypeConstraint = isValueTypeValid(
    context.commitContext.schema,
    expression.valueType
  )
    ? getConstraintFromValueType(expression.valueType)
    : null;

  const fieldPathToValueType =
    expression.object && expression.object.valueType.type === "ObjectValueType"
      ? getFieldPathToValueType(
          context.commitContext.schema,
          expression.object.valueType,
          {}
        )
      : {};

  const options = fieldValueTypeConstraint
    ? Object.keys(fieldPathToValueType)
        .filter((fieldPath) => {
          const fieldValueType = fieldPathToValueType[fieldPath];
          return isValueTypeCompatible(
            context.commitContext.schema,
            fieldValueTypeConstraint,
            fieldValueType
          );
        })
        .map((value) =>
          toLabeledOption(value, <TypeIcon type="input" size="small" />)
        )
    : [];

  const fieldPathIntent =
    context.expressionIdToIntent?.[`${expression.id}fieldPath`] ?? "neutral";
  const objectIsVariableExpression =
    expression.object &&
    expression.object.type === "VariableExpression" &&
    expression.valueType.type !== "FunctionValueType";
  const objectIsFieldArgumentsObjectVariableExpression =
    isFieldArgumentsObjectVariableExpression(expression.object);

  if (
    !context.readOnly &&
    fieldValueTypeConstraint &&
    objectIsVariableExpression
  ) {
    return (
      <InsertExpressionControl
        context={context}
        variables={variables}
        parentExpression={parentExpression}
        valueTypeConstraint={fieldValueTypeConstraint}
        setExpression={setExpression}
        shouldStack={shouldStack}
        includeExpressionOption={includeExpressionOption}
        selected={expression}
        focusOnMount={
          !!expression &&
          context.expressionEditorState.selectedItem?.id === expression.id &&
          !disablePanelOnSelect
        }
        disablePanelOnSelect={disablePanelOnSelect}
      />
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: normal,
        ...(objectIsVariableExpression
          ? { height: singlePanelInnerHeight }
          : {}),
      }}
    >
      <div>
        {objectIsFieldArgumentsObjectVariableExpression ? null : (
          <>
            <ExpressionControl
              context={context}
              variables={variables}
              setVariableName={setVariableName}
              valueTypeConstraint={{ type: "AnyObjectValueTypeConstraint" }}
              expression={expression.object}
              setExpression={(newExpression: Expression | null): void =>
                setExpression({
                  ...expression,
                  object: newExpression,
                })
              }
              lift={(child): void => {
                if (isReadOnly(context)) {
                  // eslint-disable-next-line no-alert
                  alert(liftPermissionsDeniedErrorMessage);
                  return;
                }
                function replaceArgument(
                  variable: VariableExpression | ApplicationExpression
                ): GetFieldExpression {
                  const newExpression: GetFieldExpression = {
                    ...expression,
                    object: child.replaceArgument(variable),
                  };
                  return newExpression;
                }
                const applicationExpression = createApplication({
                  variables,
                  rawArgument: child.argument,
                  replacedVariableIdToNewVariable:
                    child.replacedVariableIdToNewVariable,
                  valueType: expression.valueType,
                  replaceArgument,
                  newVariableName: child.newVariableName,
                  setExpressionEditorSelectedItem: (newSelectedItem) =>
                    context.setExpressionEditorState({
                      ...context.expressionEditorState,
                      selectedItem: newSelectedItem,
                    }),
                });
                setExpression(applicationExpression);
              }}
              parentExpression={expression}
              setParentExpression={setExpression}
              includeExpressionOption={includeExpressionOption}
            />
            <div style={{ marginLeft: 3.5 }}>{fieldPathSeparator}</div>
          </>
        )}
        {objectIsVariableExpression ? (
          <div
            className={twJoin(
              "whitespace-nowrap",
              intentToTextColorName(fieldPathIntent),
              intentToLightBgClassName(fieldPathIntent)
            )}
          >
            {toStartCase(expression.fieldPath || "")}
          </div>
        ) : (
          <Dropdown
            intent={fieldPathIntent}
            height={30}
            style={{ marginLeft: 3.5, flexGrow: 1 }}
            options={{ type: "options", options }}
            value={
              expression.fieldPath
                ? toLabeledOption(
                    expression.fieldPath,
                    <TypeIcon type="input" size="small" />
                  )
                : null
            }
            onChange={(option) => {
              if (!option) {
                return;
              }
              setExpression({
                ...expression,
                fieldPath: option.value,
              });
            }}
            placeholder="Select field..."
            noOptionsMessage="No fields found"
          />
        )}
      </div>
      {objectIsVariableExpression && optionsButton ? optionsButton : null}
    </div>
  );
}
