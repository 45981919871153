import React, { useState } from "react";
import {
  DbVercelConnection,
  ProjectTokenMapWithMeta,
  VercelEdgeConfigStoreWithTokens,
  getVercelEdgeConfigItemKey,
} from "@hypertune/shared-internal";
import { defaultBranchName } from "@hypertune/sdk/src/shared";
import { plusSymbol } from "../../../lib/constants";
import Dropdown from "../../../components/Dropdown";
import CopyableText from "../../../components/CopyableText";
import NewProjectModal from "../projects/NewProjectModal";
import NewStoreModal from "./NewStoreModal";
import { getAllToken, getQueryToken } from "../../../lib/tokens";
import Modal from "../../../components/Modal";
import Label from "../../../components/Label";
import ErrorMessage from "../../../components/ErrorMessage";
import twMerge from "../../../lib/twMerge";

const createOptionValue = "create";

export default function ConnectionEditModal({
  businessId,
  projectIdToProject,
  storeIdToStore,
  connection: initialConnection,
  flagsSecret,
  hideProjectSelector,
  saving,
  errorMessage,
  onSave,
  onClose,
}: {
  businessId: string;
  projectIdToProject: {
    [projectId: string]: { name: string; tokens: ProjectTokenMapWithMeta };
  };
  storeIdToStore: { [storeId: string]: VercelEdgeConfigStoreWithTokens };
  connection: DbVercelConnection;
  flagsSecret: string | null;
  hideProjectSelector?: boolean;
  saving: boolean;
  errorMessage: string;
  onSave: (updatedConnection: DbVercelConnection) => void;
  onClose: () => void;
}): React.ReactElement {
  const [draftConnection, setDraftConnection] =
    useState<DbVercelConnection>(initialConnection);

  const vercelStore = draftConnection?.vercelEdgeConfigStoreId
    ? storeIdToStore[draftConnection.vercelEdgeConfigStoreId] || null
    : null;

  const project = draftConnection?.projectId
    ? (projectIdToProject[draftConnection.projectId] ?? null)
    : null;

  const envVars =
    draftConnection?.vercelEdgeConfigStoreId && draftConnection?.projectId
      ? getConnectionEnvVars({
          vercelEdgeConfigStoreId: draftConnection.vercelEdgeConfigStoreId,
          vercelStore,
          projectId: draftConnection?.projectId,
          project,
          flagsSecret,
        })
      : "";

  const isValid =
    !!draftConnection?.projectId && !!draftConnection.vercelEdgeConfigStoreId;

  return (
    <Modal
      title={
        <div className="flex flex-row items-center gap-2">
          <img src="/Vercel.svg" alt="" className="h-6 w-6" />
          <Label type="title3">Vercel Edge Config connection</Label>
        </div>
      }
      closeText="Cancel"
      onClose={onClose}
      onSave={() => onSave(draftConnection)}
      saveLoading={saving}
      saveDisabled={!isValid}
      saveWeight="filled"
      className="w-[350px]"
      buttonLayout="end"
    >
      <div className="flex h-full flex-col gap-6 pb-[10px] pt-[22px]">
        {!hideProjectSelector && (
          <div>
            <Label type="title4" className="mb-2  text-tx-muted">
              Hypertune project
            </Label>
            <ProjectDropdown
              businessId={businessId}
              selectedProjectId={draftConnection.projectId}
              setSelectedProjectId={(newProjectId) =>
                setDraftConnection({
                  ...draftConnection,
                  projectId: newProjectId,
                })
              }
              projectIdToProject={projectIdToProject}
            />
          </div>
        )}

        <div>
          <Label type="title4" className="mb-2 text-tx-muted">
            Vercel Edge Config store
          </Label>
          <VercelStoreDropdown
            businessId={businessId}
            selectedStoreId={draftConnection.vercelEdgeConfigStoreId}
            setSelectedStoreIdAndOwner={(update) =>
              setDraftConnection({
                ...draftConnection,
                ...update,
              })
            }
            storeIdToStore={storeIdToStore}
          />
        </div>

        {envVars && (
          <div>
            <Label type="title4" className="mb-2 text-tx-muted">
              Environment variables
            </Label>
            <EnvVars envVars={envVars} />
          </div>
        )}
        {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
      </div>
    </Modal>
  );
}

export function EnvVars({
  envVars,
  className,
}: {
  envVars: string;
  className?: string;
}): React.ReactElement | null {
  return (
    <div>
      <CopyableText
        text={envVars}
        className={twMerge("w-full overflow-x-auto", className)}
        style={{
          minWidth:
            Math.max(...envVars.split("\n").map((line) => line.length)) * 8.5,
        }}
      />
    </div>
  );
}

export function getStoreLabel(
  store: VercelEdgeConfigStoreWithTokens | null
): string {
  return store?.slug || store?.id || "Unknown Edge Config store";
}

export function getConnectionEnvVars({
  vercelEdgeConfigStoreId,
  vercelStore,
  projectId,
  project,
  flagsSecret,
}: {
  vercelEdgeConfigStoreId: string | null;
  vercelStore: VercelEdgeConfigStoreWithTokens | null;
  projectId: number | null;
  project: { name: string; tokens: ProjectTokenMapWithMeta } | null;
  flagsSecret: string | null;
}): string {
  const vercelToken =
    (vercelStore ? vercelStore.tokens[0]?.token : null) ?? null;
  const connectionString =
    vercelEdgeConfigStoreId && vercelToken
      ? `https://edge-config.vercel.com/${vercelEdgeConfigStoreId}?token=${vercelToken}`
      : null;

  const itemKey = projectId
    ? getVercelEdgeConfigItemKey(projectId, defaultBranchName)
    : null;

  const hypertuneQueryToken = project ? getQueryToken(project.tokens) : null;
  const hypertuneAdminToken = project ? getAllToken(project.tokens) : null;

  return `NEXT_PUBLIC_HYPERTUNE_TOKEN=${
    hypertuneQueryToken || ""
  }\n\nHYPERTUNE_ADMIN_TOKEN=${hypertuneAdminToken || ""}\n\nEDGE_CONFIG=${
    connectionString || ""
  }\n\nEDGE_CONFIG_HYPERTUNE_ITEM_KEY=${itemKey || ""}\n\nFLAGS_SECRET=${
    flagsSecret || ""
  }`;
}

export function ProjectDropdown({
  businessId,
  selectedProjectId,
  setSelectedProjectId,
  projectIdToProject,
  dropdownStyle,
  readOnly,
}: {
  businessId: string;
  selectedProjectId: number | null;
  setSelectedProjectId: (newProjectId: number | null) => void;
  projectIdToProject: {
    [projectId: string]: { name: string; tokens: ProjectTokenMapWithMeta };
  };
  dropdownStyle?: {
    maxWidth?: number;
  };
  readOnly?: boolean;
}): React.ReactElement {
  const [isNewProjectModalOpen, setIsNewProjectModalOpen] =
    useState<boolean>(false);

  return (
    <>
      <Dropdown
        disabled={readOnly}
        height={34}
        maxWidth={dropdownStyle?.maxWidth}
        options={{
          type: "options",
          options: [
            ...Object.entries(projectIdToProject).map(
              ([projectId, { name }]) => ({
                label: name,
                value: projectId,
              })
            ),
            {
              label: `${plusSymbol} Create new project`,
              value: createOptionValue,
            },
          ],
        }}
        value={
          selectedProjectId
            ? {
                label:
                  projectIdToProject[selectedProjectId.toString()]?.name ||
                  `Project ${selectedProjectId}`,
                value: selectedProjectId.toString(),
              }
            : null
        }
        onChange={(newValue) => {
          if (!newValue) {
            return;
          }
          if (newValue.value === createOptionValue) {
            setIsNewProjectModalOpen(true);
            return;
          }
          setSelectedProjectId(parseInt(newValue.value));
        }}
        placeholder="Select project"
        noOptionsMessage="No projects found"
      />
      {isNewProjectModalOpen && (
        <NewProjectModal
          businessId={businessId}
          onCreate={(newProjectId) => {
            setSelectedProjectId(parseInt(newProjectId));
            setIsNewProjectModalOpen(false);
          }}
          onClose={() => {
            setIsNewProjectModalOpen(false);
          }}
        />
      )}
    </>
  );
}

export function VercelStoreDropdown({
  businessId,
  selectedStoreId,
  setSelectedStoreIdAndOwner,
  storeIdToStore,
  dropdownStyle,
  readOnly,
}: {
  businessId: string;
  selectedStoreId: string | null;
  setSelectedStoreIdAndOwner: (
    update: Omit<DbVercelConnection, "projectId">
  ) => void;
  storeIdToStore: { [storeId: string]: VercelEdgeConfigStoreWithTokens };
  dropdownStyle?: {
    maxWidth?: number;
  };
  readOnly?: boolean;
}): React.ReactElement {
  const [isNewStoreModalOpen, setIsNewStoreModalOpen] =
    useState<boolean>(false);

  return (
    <>
      <Dropdown
        disabled={readOnly}
        height={34}
        maxWidth={dropdownStyle?.maxWidth}
        options={{
          type: "options",
          options: [
            ...Object.entries(storeIdToStore).map(([storeId, store]) => ({
              label: getStoreLabel(store),
              value: storeId,
            })),
            {
              label: `${plusSymbol} Create new store`,
              value: createOptionValue,
            },
          ],
        }}
        value={
          selectedStoreId
            ? {
                label: storeIdToStore[selectedStoreId]
                  ? getStoreLabel(storeIdToStore[selectedStoreId])
                  : selectedStoreId,
                value: selectedStoreId,
              }
            : null
        }
        onChange={(newValue) => {
          if (!newValue) {
            return;
          }
          if (newValue.value === createOptionValue) {
            setIsNewStoreModalOpen(true);
            return;
          }
          const store = storeIdToStore[newValue.value];
          if (!store) {
            return;
          }
          setSelectedStoreIdAndOwner({
            vercelOwnerId: store.ownerId ?? null,
            vercelEdgeConfigStoreId: store.id ?? null,
          });
        }}
        placeholder="Select store"
        noOptionsMessage="No stores found"
      />
      {isNewStoreModalOpen && (
        <NewStoreModal
          businessId={businessId}
          onCreate={(newStore) => {
            if (!newStore) {
              return;
            }
            setSelectedStoreIdAndOwner({
              vercelOwnerId: newStore.ownerId ?? null,
              vercelEdgeConfigStoreId: newStore.id ?? null,
            });
            setIsNewStoreModalOpen(false);
          }}
          onClose={() => {
            setIsNewStoreModalOpen(false);
          }}
        />
      )}
    </>
  );
}
