import env from "../environment";

export const intentNeutralHex = "#4e576a";
export const intentWarningHex = "#EE954C";
export const intentDangerHex = "#ED544B";
export const intentSuccessHex = "#3B9E62";
export const intentPrimaryHex = "#5B67E7";

export const intentNeutralHexBg = "#F6F6F7";
export const intentWarningHexBg = "#FDF4ED";
export const intentDangerHexBg = "#FEF6F6";
export const intentSuccessHexBg = "#F5FAF7";
export const intentPrimaryHexBg = "#F6F7FD";

export const intentNeutralFilter =
  "brightness(0) saturate(100%) invert(32%) sepia(3%) saturate(3591%) hue-rotate(183deg) brightness(94%) contrast(82%)";
export const intentPrimaryFilter =
  "brightness(0) saturate(100%) invert(38%) sepia(31%) saturate(4285%) hue-rotate(222deg) brightness(95%) contrast(90%)";
export const intentWarningFilter =
  "brightness(0) saturate(100%) invert(84%) sepia(17%) saturate(7025%) hue-rotate(325deg) brightness(98%) contrast(89%)";
export const intentDangerFilter =
  "brightness(0) saturate(100%) invert(34%) sepia(52%) saturate(714%) hue-rotate(315deg) brightness(122%) contrast(106%)";
export const intentSuccessFilter =
  "brightness(0) saturate(100%) invert(47%) sepia(92%) saturate(308%) hue-rotate(91deg) brightness(92%) contrast(83%)";

export const blackHex = "#000000";
export const baseDarkGreyHex = "#303036";
export const blackTextHex = "#22252B";
export const whiteHex = "#ffffff";
export const whiteFilter =
  "invert(100%) sepia(19%) saturate(0%) hue-rotate(141deg) brightness(105%) contrast(102%)";
export const redTextHex = intentDangerHex;
export const redBgHex = "#fef6f7";
export const redBorderHex = intentDangerHex;

export const lightGreyHex = "#eaecf2";
export const greyBgHoverHex = "#EAECF180";
export const lightGrey1Hex = "#FCFCFD";
export const lightGrey2Hex = "#F4F5F8";
export const lightGrey3Hex = "#FBFBFC";
export const borderDisabledGrey = "#EEEEF1";
export const greyHex = "#EAECF1"; // Borders
export const lighterGreyHex = "#7A8396"; // Text
export const darkGreyHex = "#22252b"; // Text
export const darkGrey2Hex = "#797986"; // Text
export const darkGreyFilter =
  "invert(48%) sepia(7%) saturate(778%) hue-rotate(172deg) brightness(95%) contrast(93%)";

export const blueHex = "#5B67E7"; // Borders and text
export const blueHexSelected = "#5B67E71a";
export const blueHexPressed = "#5B67E740";
export const blueHexBoxShadow = "0px 0px 0px 2px rgba(91, 103, 231, 0.15)";
export const blueFilter =
  "invert(55%) sepia(35%) saturate(6726%) hue-rotate(187deg) brightness(101%) contrast(91%)";
export const veryLightBlueHex = "#5b67e71a";
export const lightBlueHex = "#4354FF0D"; // Backgrounds
export const darkBlueHex = "#5B67E7"; // Text on blue background
export const darkBlueFilter =
  "invert(42%) sepia(83%) saturate(5944%) hue-rotate(229deg) brightness(99%) contrast(104%)";
export const greenFilter =
  "invert(48%) sepia(50%) saturate(533%) hue-rotate(89deg) brightness(95%) contrast(89%)";

export const iconPurple = "#754CDF";
export const iconPink = "#E153B7";

/** Height of spacing to use when we need extra padding at the bottom of a
 * component that might have dropdowns etc., to make it easier for users */
export const bottomScrollSpacing = "calc(max(150px, 30vh))";

// Expression UI spacing constants
export const small = 4;
export const normal = 8;
export const spacingMedium = 10;
export const singlePanelHeight = 30;
export const singlePanelInnerHeight =
  singlePanelHeight - /* Border */ 2 - /* Padding */ normal * 2;
export const optionsButtonHeight = 22;
export const optionsButtonRight = normal;
export const optionsButtonTop = (singlePanelHeight - optionsButtonHeight) / 2;

export const paddingNormal = 10;
export const borderRadiusPx = 8;

export const sourceSansProFontFamily = "'Source Sans Pro', sans-serif";
export const interFontFamily = "Inter";
export const monospaceFontFamily = "'Source Code Pro', monospace";

// Font sizes
export const smallFontSize = 11;
export const mediumFontSize = 13;
export const medium2FontSize = 14;
export const medium3FontSize = 16;
export const largeFontSize = 26;

export const normalFontWeight = 500;
export const boldFontWeight = 600;

export const topBarHeight = 52;

export const dropdownHeight = 25;

export const defaultStepHeight = 400;

export const queryPollIntervalMs = 2000;

export const queryPollIntervalLongMs = 10_000;

// TODO: Update to a Fastly edge worker (fasthorse.edgecompute.app) if possible
export const proxyEdgeBaseUrl = "https://edge.fasthorse.workers.dev";

export const liftPermissionsDeniedErrorMessage =
  "You can't convert this expression to a variable because you don't have write permission on the parent expression that it would be defined on.";

export const docsUrl = "https://docs.hypertune.com/";
export const privacyPolicyUrl = "https://www.hypertune.com/privacy.pdf";
export const termsAndConditionsUrl = "https://www.hypertune.com/terms.pdf";
export const quickstartUrl = "https://docs.hypertune.com/quickstart";
export const calendlyUrl = "https://calendly.com/hypertune/demo";

export const draftCommitId = "draft";
export const teamGroupDisplayName = "Team";

export const dotSymbol = "‧";

export const plusSymbol = "＋";
export const upArrowSymbol = "↑";
export const downArrowSymbol = "↓";
export const leftArrowSymbol = "←";
export const rightArrowSymbol = "→";
export const functionSymbol = "𝑓";
export const rightTriangleSymbol = "▸";
export const downTriangleSymbol = "▾";
export const whiteBulletSymbol = "◦";

export const vercelAuthCodeSearchParamKey = "code";

export const medium2Font = {
  fontFamily: "Inter",
  fontSize: medium2FontSize,
  fontWeight: boldFontWeight,
  lineHeight: "24px",
  color: darkGreyHex,
};

export const sessionIdKey = "hypertuneSessionId";

export const appVersion =
  env.NAME === "development"
    ? "dev"
    : ((import.meta.env.VITE_VERSION_ID as string | undefined) ?? "unknown");

export const newAppVersionAvailableEventName = "newAppVersionAvailable";

export const newLineRegex = /\r\n|\r|\n/;
